import React from 'react';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from  'clsx';
import { position } from 'dom-helpers';

const useStyles = makeStyles((theme) => ({
    standard: {
        display: 'flex',
        color: theme.palette.primary.main
    },
    visible: {
        display: 'flex',
        position: 'absolute',
        bottom: '1%',
        right: '1%',
        border: '2px solid #45BFEE',
        color: 'white'
    },
    hidden: {
        display: 'none'
    }
}));

//In default button contextual just indicates the style to be used. 
export default function ShareButton({contextual, clickHandler, stylesOverride}){

    const [shareable, setShareable] = React.useState(false); 
    const classes = useStyles();

    React.useEffect(() => {
      setShareable(navigator && typeof navigator.share == 'function');
    });

    return (
        shareable && <IconButton size="small" aria-label="share" onClick={clickHandler} style={{...stylesOverride}}
            className={clsx(contextual ? classes.visible : classes.standard )}><ShareIcon />
        </IconButton>
    )
}

export const ContextualShareButton = ({contextual = false, currentContext, clickHandler}) => {

    const [shareable, setShareable] = React.useState(false); 
    const [context, setContext] = React.useState(false);
    const classes = useStyles();

    React.useEffect(() => {
      setShareable(navigator && typeof navigator.share == 'function');
    });

    return (
        shareable && <IconButton size="small" aria-label="share" onClick={clickHandler}
            className={clsx(contextual ? (currentContext ? classes.visible : classes.hidden) : classes.standard )}><ShareIcon />
        </IconButton>
    )
}