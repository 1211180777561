import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
      zIndex: '1'
    },
    avatarContainer: {
        '& > *:hover': {
            transform: 'scale(2)',
            zIndex: '2'
        },
        '& > *':{
          transition: '.4s ease-in-out',
          cursor: 'pointer'
        },
        '& > *:hover .coachName': {
            opacity: '1',
            zIndex: '2'
        },
        padding: '0px'
    },
    label: {
        transition: '.4s ease-in-out',
        opacity: '0',
        color: '#243E83'
    },
    // borderColor: theme.pallete.secondary
  }));

export default function AvatarMenu(props){
    const classes = useStyles();
    const menu = (child) => <Avatar style={{margin: props.variant === 'stacked' && '-2px', 
        height: child.props.width, 
        width: child.props.width,
        border: `2px solid ${child.props.selected ? '#F99E43': '#45BFEE'}`}}
        className={classes.root}
        onClick={child.props.dispatch}>{child}
    </Avatar>

    return (
        <Grid container direction='row' wrap="nowrap"  className={classes.avatarContainer} style={props.style} spacing={props.variant !=='stacked' && 3}>
            {props.children.map((child) =>{
                return <Grid item container={props.variant === 'stacked' ? false : true} direction="column" style={{alignItems: 'center', flex: props.variant!='stacked' && '1'}}>
                    {props.labelVariant === 'tooltip' ? 
                        <Tooltip title={child.props.label} placement="top-start">
                            {menu(child)}
                        </Tooltip> :
                        menu(child)
                    }
                    {props.labelVariant !== 'tooltip' && <Typography variant="subtitle" className={`coachName ${classes.label}`}>{child.props.label}</Typography>}
                </Grid>
            })}
        </Grid>
    )
}