import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import clsx from  'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CloseIcon from '@material-ui/icons/Close';
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';
import EmailIcon from '@material-ui/icons/Email';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { graphql } from "gatsby"
import CoachesData from '../content/coaches.json';
import ShareButton from '../components/ShareButton';

// import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
// import PlayArrowIcon from '@material-ui/icons/PlayArrow';
// import SkipNextIcon from '@material-ui/icons/SkipNext';

const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: '250px',
      boxSizing: 'border-box',
      width: '75vw',
      height: '85vh',
      display: 'flex',
      flexFlow: 'column',
      transition: '.5s',
      transform: 'rotateY(0deg)',
      backfaceVisibility: 'hidden',
      position: 'absolute'
    },
    cardBack: {
      height: '100%', 
      width: '100%', 
      position: 'absolute',
      transition: '.5s',
      transform: 'rotateY(180deg)',
      backfaceVisibility: 'hidden'
    },
    content: {
      flex: '1 0 auto',
      padding: '14px',
      overflowY: 'scroll',
      height: '90%',
      boxSizing: 'border-box'
    },
    header: {
      paddingTop: '0px',
      paddingBottom: '0px',
      color: 'white',
      height: '10%',
      textAlign: 'center',
      background : 'linear-gradient(20deg, #243E83, #45BFEE)',//TODO This is reused in coach card, move it to theme
      // borderBottom: '2px ridge #F78D1F'
    },
    bodyContainer: {
        height: '90%'
    },
    cover: {
      flex: '1'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      justifyContent: 'space-between',
      minHeight: '25px'
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    flipPerspective: {
      perspective: '1600px',
      width: '75vw',
      height: '85vh'
    },
    landscapePerspective: {
      perspective: '1000px',
      width: '75vw',
      height: '85vh'
    },
    flipContainer: {
      transition: '.5s',
      position: 'relative',
      display: 'block',
      transformStyle: 'preserve-3d',
      height: '100%'
    },
    front: {
      transform: 'rotateY(0deg)'
    },
    back: {
      transform: 'rotateY(-180deg)'
    },
    frontFace: {
      zIndex: 1,
      
    },
    backFace: {
      zIndex: -1   
    }
  }));

  export default function CoachCard({coachKey, image, dispatch, shareUrl}) {
    const classes = useStyles();
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [flipped, setFlipped] = React.useState(false)
    const smallHeight = useMediaQuery('(max-height: 500px)')

    const shareCoach = function(){
      try{
        navigator.share({
          title: document.title, 
          text: `Coach ${CoachesData[coachKey].name}`, 
          url: shareUrl}).then(() => {
            // console.log("shared");
          }).catch((err) => {
            // console.log(err);
          });
      }catch(e){
        // console.log('no share support', e)
      }
    }
    
    return (
      <div className={smallHeight ? classes.landscapePerspective : classes.flipPerspective}>
      <div className={clsx(classes.flipContainer, flipped ? classes.back : classes.front)}>
      <Card className={clsx(classes.root, flipped ? classes.backFace : classes.frontFace)}>
        <CardHeader title={CoachesData[coachKey].name} subheader={<i style={{color: 'white'}}>{CoachesData[coachKey].quote}</i>} className={classes.header}
            action={<IconButton aria-label="settings" onClick={dispatch}><CloseIcon style={{color: 'white', marginTop: '5px', marginRight: '-10px'}}/></IconButton>}
        />
        <Grid container={true} direction='row' wrap="nowrap" className={classes.bodyContainer}>
            <Grid item xs={9} sm={6} style={{minWidth: '165px', height: '100%', display: 'flex', flexFlow: 'column', boxSizing: 'border-box'}}>
                <CoachDetails coachData={CoachesData[coachKey]}/>
                <div className={classes.controls}>
                    <Button size="small" color="primary" variant="contained" onClick={() => setFlipped(true)}>
                      {xsScreen ? 'Train' : 'Request Training'}
                    </Button>
                    <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                        <IconButton href={`mailto:${CoachesData[coachKey].email}`} size="small" ><EmailIcon/></IconButton>
                        <IconButton size="small" ><EventIcon/></IconButton>
                        <ShareButton clickHandler={shareCoach}/>
                    </ButtonGroup>
                </div>
            </Grid>
            <Grid item xs={3} sm={6} style={{height: '100%'}}>
                <GatsbyImage image={image.portrait} alt={CoachesData[coachKey].name} style={{height: '100%'}}/>
            </Grid>
        </Grid>
      </Card>
      <Card className={clsx(classes.cardBack, flipped ? classes.frontFace : classes.backFace)} style={{display: 'flex', flexFlow: 'column'}}>
        <CardHeader className={classes.header} style={{textAlign: 'left'}} subheaderTypographyProps={{color: 'white'}} title={`Training Requset With ${CoachesData[coachKey].name}`} subheader={!xsScreen && CoachesData[coachKey].quote}
          action={<IconButton onClick={() => setFlipped(false)}><CloseIcon style={{color: 'white', marginTop: '5px', marginRight: '-10px'}}/></IconButton>}/>
        <CardContent className={classes.content} style={{padding: '0px'}}>
          <Grid container direction="row" style={{height: '100%'}}>
          <Grid item sm={8} md={6} xs={12}>
            <iframe src={`https://formcrafts.com/a/${CoachesData[coachKey].formId}?iframe=true&resizer=false&innerHeight=625`} width='100%' height='100%'
            style={{maxWidth: '100%', border: '0', boxShadow: '0 0 0 .5px rgba(30, 30, 30, .1), 0px 1px 2px rgb(30, 30, 30, .08)', 
            borderRadius: '3px'}}>Loading</iframe>
          </Grid>
          {!xsScreen && <Grid item sm={4} md={6} style={{height: '100%'}}>
              <GatsbyImage image={image.action} alt={CoachesData[coachKey].name} style={{height: '100%'}}/>
          </Grid>}
          </Grid>
        </CardContent>
      </Card>
      </div>
      </div>
    );
  }

  export const CoachDetails = ({coachData = {}}) => {
    const classes = useStyles();

    return(
    <CardContent className={classes.content}>
      <Grid container direction="column" spacing={2} style={{padding: '1rem'}}>
        <Grid item>
          <Typography variant="subtitle1" style={{borderBottom: '1px solid #F78D1F', marginBottom: '10px', color: '#243E83'}}>
            Coaching Experience
          </Typography>
          <Typography variant="p">
            {coachData.coachingExp}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" style={{borderBottom: '1px solid #F78D1F', marginBottom: '10px', color: '#243E83'}}>
            Playing Experience
          </Typography>
          <Typography variant="p">
            {coachData.playingExp}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" style={{borderBottom: '1px solid #F78D1F', marginBottom: '10px', color: '#243E83'}}>
            Coaching Philosophy
          </Typography>
          <Typography variant="p">
            {coachData.philosophy}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" style={{borderBottom: '1px solid #F78D1F', marginBottom: '10px', color: '#243E83'}}>
              Favorite Volleyball Moment
          </Typography>
          <Typography variant="p">
            {coachData.favMoment}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" style={{borderBottom: '1px solid #F78D1F', marginBottom: '10px', color: '#243E83'}}>
              Fun Fact
          </Typography>
          <Typography variant="p">
            {coachData.funFact}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>)
  }

export const cardImage = graphql`
  fragment cardImage on File {
    childImageSharp {
      gatsbyImageData(
        placeholder: TRACED_SVG
        formats: [AUTO, WEBP, AVIF]
      )
      resize {
        height
        width
        src
      }
    }
  }
`